@import '../../../../scss/theme-bootstrap';

///
/// @file components/prouducts/_product_grid.scss
///
/// \brief Styles for multi-product grids
///
///
.product-grid {
  width: 100%;
  position: relative;
  text-align: center;

  .mpp-container &,
  .mpp__align-rows-left & {
    text-align: #{$ldirection};
    .product-grid__content {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .mpp__align-rows-center & {
    text-align: center;
    .product-grid__content {
      align-items: center;
      justify-content: center;
    }
  }

  &.product-grid--small-cols-2 .product-grid__item {
    width: 49.5%;
  }

  .product-grid__content {
    @include pie-clearfix;
    //max-width: $max-width-large;
    margin: 0 10px 1px;
    display: flex;
    flex-wrap: wrap;
    width: auto;
  }

  .product-grid__item {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 25px;
  }
  &.product-grid--cols-padding-5 .product-grid__item {
    padding: 0 5px;
  }
  &.product-grid--cols-padding-10 .product-grid__item {
    padding: 0 10px;
  }
  &.product-grid--cols-padding-15 .product-grid__item {
    padding: 0 15px;
  }
  &.product-grid--cols-padding-20 .product-grid__item {
    padding: 0 20px;
  }
  &.product-grid--small-cols-2 .product-grid__item {
    width: 48%;
  }
  &.product-grid--small-cols-3 .product-grid__item {
    width: 33%;
  }

  @include breakpoint($medium-up) {
    &.product-grid--medium-cols-1 .product-grid__item {
      width: auto;
      float: none;
    }
    &.product-grid--medium-cols-2 .product-grid__item {
      width: 50%;
    }
    &.product-grid--medium-cols-3 .product-grid__item {
      width: 33%;
    }
    &.product-grid--medium-cols-4 .product-grid__item {
      width: 25%;
      &.product-grid__item--colspan-2 {
        width: 50%;
        margin-top: 0;
      }
      &.product-grid__item--colspan-3 {
        width: 75%;
        margin-top: 0;
      }
      &.product-grid__item--colspan-4 {
        width: 100%;
      }
    }
    &.product-grid--medium-cols-5 .product-grid__item {
      width: 20%;
    }
  }

  &--carousel {
    .product-carousel {
      &.slick-initialized {
        display: block;
      }
    }
  }

  &.product-grid--has-header-tout {
    .product-grid__content {
      margin: 0 0 1px;
    }
    .grid__item--first-col {
      clear: both;
    }
  }
}
